<template>
  <NavBarComponent/>
</template>

<script>
// @ is an alias to /src
import NavBarComponent from '@/components/NavBarComponent.vue'

export default {
  name: 'app',
  components: {
    NavBarComponent
  }
}
</script>


<style lang="scss">

body{
  background-color: #110215;
}

a {
    position: relative;
    color: #6AE895;
    text-decoration: none;
    transition: transform 0.3s ease, color 0.3s ease;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -3px;
        width: 100%; 
        height: 2px; 
        background-color: #E86ABD; 
        transform: scaleX(0); 
        transition: transform 0.3s ease;
    }

    &:hover {
        transform: scale(1.1); 
        color: #E86ABD; 

        &::after {
            transform: scaleX(1); 
        }
    }
}

body {
  background-color: #110215;
  font-family: 'BigJ', sans-serif; /* Use the font name defined in @font-face */
}

@font-face {
  font-family: 'BigJ';
  src: url('../public/fonts/BigJohnPRO-Regular.otf') format('opentype'); // Relative path without leading slash
  font-display: swap; 
}

@font-face {
  font-family: 'Dense';
  src: url('../public/fonts/DenseLetters-nRgDO.otf') format('opentype'); // Relative path without leading slash
  font-display: swap; 
}

#app {
  font-family: 'BigJ', sans-serif; /* Fallback to sans-serif if BigJ is unavailable */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

</style>
