<template>
    <div class="project-grid-container">
        <div class="filter-container">
            <input 
                type="text" 
                v-model="searchTerm" 
                id="search"
                placeholder="Search projects..." 
                class="search-input" 
            />
            
            <div class="sort-container">
                <label for="sort" class="floating-label">Sort by:</label>
                <select v-model="sortOption" id="sort" class="sort-select">
                    <option value="updated_at">Last Updated</option>
                    <option value="name">Name</option>
                </select>
            </div>
        </div>

        <div v-if="loadTime" class="load-time">
            Loaded in {{ loadTime }} ms
        </div>

        <div class="tags-container">
            <span 
                v-for="language in uniqueLanguages" 
                :key="language" 
                :class="['tag', { selected: selectedTags.includes(language) }]" 
                @click="toggleTag(language)"
            >
                {{ language }}
            </span>
        </div>

        <div class="project-grid">
            <div v-for="project in filteredProjects" :key="project.id" class="project-card">
                <h3>{{ project.name }}</h3>
                <p>
                    <span v-html="highlightMatches(truncatedDescription(project), searchTerm)"></span>
                    <span v-if="isLongDescription(project.description)">
                        <a :href="project.html_url" target="_blank" class="read-more-link" 
                           :aria-label='"Read more about " + project.name' >Read more <span class="screen-reader-text">about {{ project.name }}</span></a>
                    </span>
                </p>
                
                <a v-if="project.homepage" :href="project.homepage" target="_blank" class="homepage-link">Visit live site!</a>

                <div v-if="project.topics.length !== 0" class="topics-container">
                    <h4>Topics:</h4>
                    <span v-for="topic in project.topics" :key="topic" class="topic-tag">{{ topic }}</span>
                </div>

                <div v-if="project.images && project.images.length" class="swiper-container">
                    <swiper :pagination="{ clickable: true }" :navigation="true" loop class="mySwiper">
                        <swiper-slide v-for="(image, index) in project.images" :key="index">
                            <img :src="image" alt="Project Image" class="project-image" loading="lazy" />
                        </swiper-slide>
                    </swiper>
                </div>

                <div class="languages">
                    <h4>Languages Used:</h4>
                    <ul>
                        <li v-for="(percentage, language) in project.languages" :key="language">
                            <span v-html="highlightMatches(language, searchTerm)"></span>: {{ percentage }}%
                        </li>
                    </ul>
                </div>
                <a :href="project.html_url" target="_blank" class="project-link">View Project</a>
            </div>
        </div>
    </div>
</template>

<script>
import projectsData from '@/data/projects.json';
import { Swiper, SwiperSlide } from 'swiper/vue'; 
import 'swiper/swiper-bundle.css'; 

export default {
    name: 'ProjectGrid',
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            projects: projectsData,
            searchTerm: '',
            sortOption: 'updated_at',
            selectedTags: [],
            loadTime: null
        };
    },
    computed: {
        uniqueLanguages() {
            const languages = new Set();
            this.projects.forEach(project => {
                Object.keys(project.languages).forEach(lang => languages.add(lang));
            });
            return Array.from(languages);
        },
        filteredProjects() {
            const filtered = this.projects
                .filter(project => {
                    const projectNameMatch = project.name.toLowerCase().includes(this.searchTerm.toLowerCase());
                    const projectDescriptionMatch = project.description ? project.description.toLowerCase().includes(this.searchTerm.toLowerCase()) : false;

                    const languagesMatch = Object.keys(project.languages).some(language =>
                        language.toLowerCase().includes(this.searchTerm.toLowerCase())
                    );

                    const tagsMatch = this.selectedTags.length === 0 || 
                        Object.keys(project.languages).some(language => this.selectedTags.includes(language));

                    return (projectNameMatch || projectDescriptionMatch || languagesMatch) && tagsMatch;
                });

            return filtered.sort((a, b) => {
                if (this.sortOption === 'updated_at') {
                    return new Date(b.updated_at) - new Date(a.updated_at);
                } else {
                    return a.name.localeCompare(b.name);
                }
            });
        },
    },
    methods: {
        truncatedDescription(project) {
            const maxLength = 100; 
            return project.description && project.description.length > maxLength
                ? project.description.substring(0, maxLength) + '...'
                : project.description || 'No description available.';
        },
        isLongDescription(description) {
            return description && description.length > 100; 
        },
        highlightMatches(text, searchTerm) {
            if (!searchTerm) return text; 
            const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const regex = new RegExp(`(${escapedSearchTerm})`, 'gi'); 
            return text.replace(regex, '<span class="highlight">$1</span>'); 
        },
        toggleTag(language) {
            const index = this.selectedTags.indexOf(language);
            if (index > -1) {
                this.selectedTags.splice(index, 1); 
            } else {
                this.selectedTags.push(language); 
            }
        },
    },
}
</script>

<style scoped>
.project-grid-container {
    padding: 20px; 
    color: #ffffff; 
}

.load-time {
    margin-bottom: 20px; 
    font-size: 14px; 
    color: #E86ABD; 
}

.filter-container {
    display: flex;
    justify-content: space-between; 
    margin-bottom: 20px; 
}

.sort-container {
    position: relative;
}

.floating-label {
    position: absolute;
    top: -25px;
    font-size: 14px;
    color: #6AE895;
    padding: 0 5px;
    pointer-events: none;
}


.search-input, .sort-select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #333;
    color: #fff;
    background-color: #1a1a1a; 
}

.tags-container {
    margin-bottom: 20px; 
}

.tag {
    display: inline-block;
    padding: 5px 10px;
    margin-right: 10px;
    border: 1px solid #6AE895; 
    border-radius: 5px;
    background-color: #1a1a1a; 
    color: #6AE895; 
    cursor: pointer; 
    transition: background-color 0.3s, color 0.3s; 
}

.tag:hover {
    border: 1px solid #E86ABD; 
    color: #E86ABD; 
}

.tag.selected {
    border: 1px solid #E86ABD; 
    background-color: #1a1a1a; 
    color: #E86ABD; 
}

.project-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
    gap: 20px; 
}

.project-card {
    background-color: #1a1a1a; 
    border: 1px solid #333; 
    border-radius: 8px;
    padding: 15px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); 
    transition: transform 0.2s, box-shadow 0.2s; 
}

.project-card:hover {
    transform: translateY(-3px); 
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7); 
}

.languages {
    margin-top: 10px;
}

.languages h4 {
    margin-bottom: 5px; 
    color: #6AE895; 
}

.languages ul {
    padding-left: 15px; 
}

.languages li {
    color: #ffffff; 
}

.project-link {
    display: inline-block;
    margin-top: 10px; 
    color: #E86ABD; 
    text-decoration: none;
    transition: color 0.3s ease; 
}

.project-link:hover {
    text-decoration: underline; 
    color: #6AE895; 
}

.read-more-link {
    color: #E86ABD; 
    margin-left: 5px; 
    text-decoration: underline; 
}

.highlight {
    background-color: #E86ABD; 
    color: #fff; 
}

.topics-container {
    display: none;
    margin: 10px 0; 
}

.topic-tag {
    display: inline-block;
    padding: 5px 10px;
    margin-right: 8px;
    margin-bottom: 8px;
    border: 1px solid #6AE895; 
    border-radius: 5px;
    background-color: #1a1a1a; 
    color: #6AE895; 
    cursor: default; 
    transition: background-color 0.3s, color 0.3s; 
}

.topic-tag:hover {
    background-color: #E86ABD; 
    color: #fff; 
}

.swiper-container {
    display: none;
    width: 100%;
}

.project-image {
    width: 100%; /* Make images responsive */
    height: auto; 
    object-fit: cover; 
    aspect-ratio: 1/1;
}

.swiper-button-next, .swiper-button-prev {
    color: #fff; 
}

.swiper-pagination-bullet {
    background: #fff; 
}

.screen-reader-text {
    opacity: 0;
    font-size: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .filter-container {
        flex-direction: column; /* Stack elements vertically on mobile */
    }

    .search-input, .sort-select {
        margin-bottom: 10px; /* Space between input and select */
    }

    .floating-label {
    position: static;
    top: -25px;
    font-size: 14px;
    color: #6AE895;
    padding: 0 5px;
    pointer-events: none;
}

    .project-card {
        padding: 10px; /* Reduced padding for mobile */
    }

    .load-time {
        font-size: 12px; /* Smaller load time font size */
    }

    .tag {
        margin-right: 5px; /* Reduced margin for tags */
        font-size: 14px; /* Adjust font size for better readability */
    }

    .languages h4 {
        font-size: 16px; /* Slightly smaller font size for headings */
    }

    .project-link {
        font-size: 14px; /* Adjust font size for better readability */
    }

    .read-more-link {
        font-size: 14px; /* Adjust font size for better readability */
    }
}
</style>
