<template>
    <span class="hover-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
      <slot></slot>
      <span v-if="visible" class="tooltip">
        <div>
          "<span class="highlight">Ú</span>" is a letter in the Faroese alphabet, representing the long vowel sound similar to the English 'oo' in "food." It is crucial in Faroese phonetics and can change the meaning of words. For example, "húsa" (houses) uses "<span class="highlight">ú</span>" to indicate a distinct vowel quality.
        </div>
        <div>
          <strong>Pronunciation:</strong> /uː/ (like in "boot" or "moon"). 
          <button @click="playPronunciation" class="pronunciation-button">🔊</button>
        </div>
      </span>
    </span>
  </template>
  
  <script>
  export default {
    name: 'TooltipComponent',
    data() {
      return {
        visible: false
      };
    },
    methods: {
      showTooltip() {
        this.visible = true;
      },
      hideTooltip() {
        this.visible = false;
      },
      playPronunciation() {
        const audio = new Audio(require('@/assets/mp3/USpoken.mp3'));
        audio.play();
      }
    }
  }
  </script>
  
  <style scoped>
  .hover-container {
    position: relative; 
    display: inline-block; 
  }
  
  .tooltip {
    visibility: visible; 
    opacity: 1; 
    position: absolute;
    left: 0;
    bottom: -100px; 
    background-color: #6AE895; 
    color: #0a0514; 
    padding: 5px;
    border-radius: 4px;
    transition: opacity 0.3s; 
    white-space: normal; 
    z-index: 10; 
    width: 20vw; /* Allow tooltip width to adjust */
    font-size: 0.8em; 
  }
  
  .pronunciation-button {
    background: none; 
    border: none; 
    cursor: pointer; 
    color: #E86ABD; 
    font-size: 1em; 
  }
  
  .highlight {
    font-weight: bold; 
    color: inherit; /* Default color inherited from the link */
  }
  </style>
