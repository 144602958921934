<template>
  <div class="boldText-container" role="region" aria-label="Highlighted Text">
    <p class="boldText-container__text">
      <span 
        v-for="(word, index) in processedText" 
        :key="index"
        :class="[getHighlightClass(word), isHovered ? 'hover-active' : '']"
        @mouseover="toggleHover(true)"
        @mouseleave="toggleHover(false)"
        tabindex="0" 
        @focus="toggleHover(true)"  
        @blur="toggleHover(false)" 
      >
        {{ word }}<span v-if="index < processedText.length - 1">&nbsp;</span>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'BoldText',
  props: {
    txt: {
      type: String,
      required: true
    },
    highlightWords: {
      type: Array,
      required: true,
      validator: (value) => value.every(word => typeof word === 'string')
    }
  },
  data() {
    return {
      isHovered: false
    };
  },
  computed: {
    processedText() {
      return this.txt.split(' ');
    }
  },
  methods: {
    getHighlightClass(word) {
      const lowerWord = word.toLowerCase();
      const highlightIndex = this.highlightWords.indexOf(lowerWord);
      return highlightIndex === 0 ? 'highlight-red' : highlightIndex === 1 ? 'highlight-green' : '';
    },
    toggleHover(state) {
      this.isHovered = state;
    }
  }
}
</script>

<style lang="scss" scoped>
.boldText-container {
  cursor: default;

  &__text {
    text-transform: uppercase;
    font-family: Dense, sans-serif;  
    font-size: 1.5rem; /* Adjust font size for larger screens */
    word-break: break-word; /* Allow words to break */
    white-space: normal; /* Allow normal wrapping of text */
  }

  .highlight-red {
    color: #6AE895;
    transition: color 0.3s;
  }

  .highlight-green {
    color: #E86ABD;
    transition: color 0.3s;
  }

  .hover-active.highlight-red {
    color: #E86ABD;
  }

  .hover-active.highlight-green {
    color: #6AE895;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .boldText-container__text {
    font-size: 1.2rem; /* Adjust font size for mobile */
  }
}
</style>
