<template>
  <nav>
    <div class="nav-links">
      <router-link to="/">Home</router-link>
      <router-link to="/contact">Contact</router-link>
      <router-link to="/projects">Projects</router-link>
    </div>
  </nav>

  <router-view/>
</template>

<style lang="scss" scoped>
nav {
  padding: 20px; /* Padding around the nav */
}

.nav-links {
  display: flex; /* Flexbox for layout */
  justify-content: center; /* Center links */
  flex-wrap: wrap; /* Allow wrapping on small screens */
}

a {
  font-weight: bold;
  color: #ffffff;
  margin: 0 10px; /* Space between links */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s; /* Smooth color transition */

  &:hover {
    color: #42b983; /* Change color on hover */
  }

  &.router-link-exact-active {
    color: #42b983; /* Active link color */
  }
}

</style>
