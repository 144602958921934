<template>
  <div class="home">
    <div class="grid-container">
      <div class="left-container">
        <p class="home__heading">
          Hello, I am 
          <TooltipComponent 
            highlightedChar="Ú" 
            pronunciation="/uː/" 
            audioPath="@/assets/mp3/USpoken.mp3">
            <a href="https://www.linkedin.com/in/r%C3%BAni-gudmundarson-b33559176/" target="_blank">
              R<span class="highlight">ú</span>ni
            </a>
          </TooltipComponent> 👋
        </p>
        <BoldText txt="and I love to build things" :highlightWords="['love', 'build']"/>
        
        <p class="home__description">
          I am a passionate and ambitious junior developer currently studying Computer Science at 
          <a href="https://www.ucn.dk/" target="_blank">UCN Aalborg</a>. With a strong background in web development and a degree in Web Development, I have experience with technologies like HTML, CSS, JavaScript, and frameworks such as Vue.js and React.
          I enjoy working on innovative projects, including IoT solutions like a custom SMS gateway and an automated gin machine. I thrive on tackling new challenges and continuously learning to enhance my skills.
          Let’s connect and explore how we can collaborate on your next project! Want to know more? <a href="/data/CV-EN.pdf" download>Download my resume</a>.
        </p>

        <div class="history">
          <h2>History</h2>

          <div class="history-section">
            <h3>Hiring History</h3>
            <ul>
              <li>
                <strong>Frontend Developer</strong> at <a href="https://www.gotcha.dk/" target="_blank">Gotcha A/S</a> (March 2023 - August 2024)
                <ul>
                  <li>Developed and launched major online e-commerce stores.</li>
                  <li>On a three-man team developing a new e-commerce store for a global seller with over 13,000 products.</li>
                  <li>Implemented PIM and a new website for one of Europe's largest aquariums, streamlining product information.</li>
                  <li>Initiated discussions on integrating ChatGPT for automatic SEO metadata generation for 13,000+ products.</li>
                  <li>Refactored legacy hardcoded info screens using Vue.js and API calls.</li>
                  <li>Improved page load speed significantly for a customer by implementing lazy loading for images, resolving path issues related to illegal characters (e.g., spaces), and optimizing performance to handle 4K image uploads, which had previously caused poor compression and display errors.</li>
                </ul>
              </li>
              <li>
                <strong>Full stack developer</strong> (Freelance, Fiverr) (January 2022 - April 2023)
                <ul>
                  <li>Developed custom browser sources for Twitch streamers to enhance user interaction and viewer experience.</li>
                </ul>
              </li>
            </ul>
          </div>

          <div class="history-section">
            <h3>Education History</h3>
            <ul>
              <li>
                <strong>Computer Science</strong> at <a href="https://www.ucn.dk/" target="_blank">UCN Aalborg</a> (2023 - Present)
              </li>
              <li>
                <strong>Web Development Program</strong> at <a href="https://www.techcollege.dk/" target="_blank">Tech College</a> (August 2021 – March 2023)
                <ul>
                  <li>Completed with a final grade of 12, gaining extensive skills in web-based coding and programming.</li>
                  <li>Proficient in JavaScript, React.js, and React Native.</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="right-container">
          <h2>Skill Proficiency</h2>
          <div class="skill-bars">
              <div class="skill" v-for="skill in skills" :key="skill.name" @click="filterProjects(skill.name)">
                  <span>{{ skill.name }}</span>
                  <div class="bar">
                      <div class="progress" :style="{ width: skill.level + '%' }"></div>
                  </div>
                  <span class="experience">{{ skill.experience }}</span>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import BoldText from '@/components/BoldText'
import TooltipComponent from '@/components/Tooltip'

export default {
  name: 'HomeView',
  components: {
    BoldText,
    TooltipComponent,
  },
  data() {
    return {
        skills: [
            { name: 'HTML', level: 90, experience: '5 years' },
            { name: 'CSS', level: 85, experience: '5 years' },
            { name: 'JavaScript', level: 80, experience: '5 years' },
            { name: 'Java', level: 75, experience: 'Less than a year' },
            { name: 'C#', level: 70, experience: '1 year' },
            { name: 'C++ (Arduino)', level: 60, experience: '1 year' },
            { name: 'SQL', level: 65, experience: '2 years' },
            { name: 'React', level: 75, experience: '2 years' },
            { name: 'Vue', level: 70, experience: '2 years' },
            { name: 'React Native', level: 65, experience: '1 year' },
            { name: 'Raspberry Pi', level: 50, experience: '1 year' },
        ],
    };
},
}
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center; // Center align items vertically if needed
  padding: 20px;
  min-height: 100vh; // Ensure full viewport height for vertical centering
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto; // Center grid-container horizontally
}

.left-container {
  padding: 10px; /* Reduced padding for better fit */
}

.right-container {
  padding: 10px; /* Reduced padding for better fit */
}

.skill-bars {
  margin-top: 20px;
}

.skill {
  margin-bottom: 10px; 
}

.bar {
  background: #ddd; 
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  height: 10px; 
}

.progress {
  background: rgba(232, 106, 189, 1); 
  height: 100%;
}

.experience {
  display: block;
  font-size: 0.9em; 
  color: #ffffffc8; 
  margin-top: 5px; 
}

.history {
  margin-top: 40px; 
}

.history h2 {
  margin-bottom: 20px; 
}

.history h3 {
  margin-top: 20px; 
}

.history-section {
  margin-bottom: 30px; 
}

.history ul {
  list-style-type: none; 
  padding: 0; 
}

.history li {
  margin-bottom: 10px; 
}

/* Responsive Styles */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr; /* Single column layout */
  }

  .right-container, .left-container {
    padding: 10px; /* Further reduced padding for mobile */
  }

  .skill-bars {
    margin-top: 10px; 
  }

  .skill {
    margin-bottom: 5px; 
  }

  .experience {
    font-size: 0.8em; 
  }

  h2, h3, p {
    font-size: 90%; /* Adjust font size for mobile */
    line-height: 1.4; /* Improve readability */
  }

  a {
    padding: 10px; /* Increase touch target size */
    display: inline-block; /* Make links behave more like buttons */
  }
}
</style>
